<template>
  <div class="card text-center h-100"
    :class="obtenerClaseCard(issuserParam.estiloProductos ? issuserParam.estiloProductos : 1)">
    <div class="position-relative overflow-hidden shadow-lg p-2 ">
      <a href="javascript:void(0)">
        <img :src="[
          producto.imagen.includes('no-img')
            ? PUBLICPATH + 'img/new-products/no-img.jpg'
            : producto.ruta + producto.imagen,
        ]" class="card-img-top img-escritorio img-telefono" alt="Imagen" :style="[
  `object-fit: ${getStrategyImage(issuserParam.estrategiaImagen)
    ? getStrategyImage(issuserParam.estrategiaImagen)
    : getStrategyImage('2')
  }`,
  styleImage,
]" @click="viewDetail(producto)" />
        <img v-if="producto.stock != null && producto.stock <= 0" class="position-absolute top-0 start-0"
          :src="[PUBLICPATH + 'img/new-products/sinStock.png']" alt="Sin Stock" @click="viewDetail(producto)" />
        <h5 class="position-absolute top-0 end-0 card-title" v-on:click="toggleFav(producto.codigo)">
          <span class="badge rounded-pill bg-light" :class="[
            favList.includes(producto.codigo)
              ? 'text-warning'
              : 'text-secondary',
          ]">
            <fa icon="star" />
          </span>
        </h5>
        <h5 class="position-absolute bottom-0 end-0 card-title" v-if="parseFloat(producto.pctDescuento)">
          <span class="badge rounded-pill bg-danger">
            <fa icon="tag" /> -{{ producto.pctDescuento }}%
          </span>
        </h5>
      </a>
    </div>
    <div v-if="issuserParam.deshabilitarCarrito != 'S'" class="card-body pb-3">
      <p v-if="issuserParam.mostrarcodigoproductocatalogo == 'S' && issuserParam.tipoMarketTienda != 1"
        class="card-title lh-1">
        <a href="javascript:void(0)" class="text-secondary text-decoration-none" style="
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              " @click="viewDetail(producto)">{{ producto.nombreProducto + "(" + producto.codigo + ")" }}</a>
      </p>
      <p v-if="issuserParam.mostrarcodigoproductocatalogo == 'S' && issuserParam.tipoMarketTienda == 1"
        class="card-title lh-1">
        <a href="javascript:void(0)" class="text-secondary text-decoration-none subtitle-client" style="
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              " @click="viewDetail(producto)">{{ producto.nombreProducto + "(" + producto.idFamilia + ")" }}</a>
      </p>
      <p v-if="issuserParam.mostrarcodigoproductocatalogo != 'S'" class="card-title lh-1">
        <a href="javascript:void(0)" class="text-secondary text-decoration-none subtitle-client" style="-webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              " @click="viewDetail(producto)">{{ producto.nombreProducto }}</a>
      </p>
      <h5 class="card-subtitle text-decoration-line-through fw-bold"
        v-if="parseFloat(producto.PctDescuento) && issuserParam.deshabilitarPrecios != 'S'">
        {{ formatMoney(issuserParam.monedalp) }}
        {{ parseFloat(producto.precioUnitario).toFixed(decimal) }}
      </h5>
      <div v-if="issuserParam.deshabilitarPrecios != 'S'">
        <div class="row justify-content-center">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <h5 class="card-subtitle fw-bold h5_card_precio precio-carrito-escritorio m-0 precio-carrito-telefono">
              {{ formatMoney(issuserParam.monedalp) }}
              {{
                formatPrice(
                  parseFloat(
                    calcSalePrice(
                      producto.precioUnitario,
                      producto.pctDescuento,
                      decimal
                    )
                  ) +
                  adjust10th(
                    issuserParam.configRedondeoDecimas,
                    calcSalePrice(
                      producto.precioUnitario,
                      producto.pctDescuento,
                      decimal
                    )
                  ),
                  decimal
                )
              }}
            </h5>
          </div>
        </div>
        <div v-if="descuentosUnics.length">
          <div class="row d-flex justify-content-center py-1" v-for="descuento in descuentosUnics" :key="descuento.banco">
            <div class="col-10 justify-content-start col-md-6 col-lg-5 p-0">
              <img style="width: 25px;" :src="'/img/mediospago/' + descuento.logoBanco">
              <label class="precio-descuento mb-0 mt-1 fw-bold">
                {{ formatMoney(issuserParam.monedalp) }}{{ calcSalePrice(producto.precioUnitario,
                  parseFloat(producto.pctDescuento)
                  + parseFloat(descuento.descComercio) +
                  parseFloat(descuento.descBanco), decimal) }}
              </label>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-sm-12 col-md-12 col-lg-3 p-0">
            <button class="btn btn-client btn-carrito-escritorio btn-carrito-telefono m-0"
              @click="saveProducto(producto)">
              <fa class="icon-carrito-telefono" icon="cart-plus" />
            </button>
          </div>
        </div>

      </div>
    </div>
    <div v-if="issuserParam.deshabilitarCarrito == 'S'" class="card-body">
      <p class="card-title lh-1">
        <a href="javascript:void(0)" class="text-secondary text-decoration-none"
          style="-webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis; display: -webkit-box;-webkit-box-orient: vertical;"
          @click="viewDetail(producto)">{{ producto.nombreProducto }}</a>
      </p>
      <h5 class="card-subtitle text-decoration-line-through fw-bold" v-if="parseFloat(producto.pctDescuento)">
        {{ formatMoney(issuserParam.monedalp) }}
        {{ parseFloat(producto.precioUnitario).toFixed(decimal) }}
      </h5>
      <h5 class="card-subtitle fw-bold h5_card_precio" v-if="issuserParam.deshabilitarPrecios != 'S'">
        {{ formatMoney(issuserParam.monedalp) }}
        {{
          formatPrice(
            parseFloat(
              calcSalePrice(
                producto.precioUnitario,
                producto.pctDescuento,
                decimal
              )
            ) +
            adjust10th(
              issuserParam.configRedondeoDecimas,
              calcSalePrice(
                producto.precioUnitario,
                producto.pctDescuento,
                decimal
              )
            ),
            decimal
          )
        }}
      </h5>
    </div>
    <div v-if="mostrarBotonesCantidad()" class="card-footer bg-transparent border-light">
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="input-group cantbuttons justify-content-center">
            <button class="btn btn-outline-secondary minusbutton rounded-3" type="button"
              v-on:click="restarCantidad(producto)">
              <fa icon="minus" />
            </button>
            <input class="form-control inputcount text-center border-0" type="number" v-model="cantidad" :run="cantidad"
              style="width: 25%; flex: none; margin: 1px; padding: inherit" />
            <button class="btn btn-outline-secondary plusbutton rounded-3" type="button"
              v-on:click="sumarCantidad(producto)">
              <fa icon="plus" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, computed } from "vue";
import {
  adjust10th,
  getStrategyImage,
  getResponsiveCard,
  formatPrice,
  validarProductoPesable,
  desplegarSwalProductoPesable,
  calcSalePrice
} from "@/composable/functions";
import router from "@/router";
import Swal from "sweetalert2";

export default {
  name: "article-product",
  props: {
    product: Object,
    classes: String,
    styleImage: String,
  },
  data: function () {
    return {
      producto: this.product,
    };
  },
  setup() {
    const decimal = inject("decimal");
    const issuserParam = inject("issuserParam");
    const saveProductoApp = inject("saveProducto");
    const styleClass = inject("styleClass");
    const favList = inject("favList");
    const PUBLICPATH = inject("PUBLICPATH");
    const cantidad = ref(1);
    const descuentos = inject("descuentos");


    const descuentosUnics = computed(() => {
      let arrayDescuentos = [];
      if (!descuentos.value || descuentos.value.length == 0) {
        return arrayDescuentos;
      }
      arrayDescuentos = descuentos.value.filter((item, index) => {
        return descuentos.value.indexOf(descuentos.value.find((desc) => desc.idBanco === item.idBanco)) === index;
      })
      return arrayDescuentos;
    });


    const sumarCantidad = (producto) => {
      cantidad.value++;
      producto.cantidad = cantidad.value;
    };
    const restarCantidad = (producto) => {
      cantidad.value--;
      producto.cantidad = cantidad.value;
    };
    const toggleFav = (codigo) => {
      if (favList.value.includes(codigo)) {
        favList.value = favList.value.filter((item) => item !== codigo);
      } else {
        favList.value.push(codigo);
      }
      localStorage.setItem("fav", JSON.stringify(favList.value));
    };

    const viewDetail = (producto) => {
      if (producto.idProductoBase) {
        router.push({ name: "DetailArticle", params: { id: producto.idProductoBase } });
        return;
      }
      router.push({ name: "DetailArticle", params: { id: producto.codigo } });
    };

    const saveProducto = (producto) => {
      if (producto.codigo && producto.idProductoBase) {
        viewDetail(producto);
        return;
      }

      producto.cantidad = cantidad.value;
      if (!validarProductoPesable(producto)) {
        desplegarSwalProductoPesable("Este producto no se puede agregar al carrito con una cantidad fraccionada", "Si desea agregarlo al carrito debe colocar una cantidad como número entero ej: 1");
        cantidad.value = 1
        return;
      }
      producto.sinStock = false;
      producto.carritoSuperiorStock = false;
      saveProductoApp(producto);
      if (producto.sinStock || producto.carritoSuperiorStock) {
        return producto;
      }
      Swal.fire({
        icon: "success",
        title: "Producto agregado al carrito!",
        showDenyButton: true,
        confirmButtonText: "Ir al carrito",
        denyButtonText: `Seguir comprando`,
        confirmButtonColor: styleClass.value,
        customClass: {
          denyButton: 'btn-client-invertido btn-client-border'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          router.push({ name: "Cart" });
        }
      });
    };

    const obtenerClaseCard = (clase) => {
      let claseCard = "";
      switch (clase) {
        case "2":
          claseCard = "card-product"
          break;
        default:
          claseCard = ""
          break;
      }
      return claseCard;
    }

    const mostrarBotonesCantidad = () => {
      return issuserParam.value.deshabilitarCarrito != 'S'
        && issuserParam.value.tipoMarketTienda != 1
        && issuserParam.value.tipoMarketTienda != 2
        && issuserParam.value.botonesCantidad != "N"
    }


    return {
      mostrarBotonesCantidad,
      obtenerClaseCard,
      calcSalePrice,
      cantidad,
      favList,
      sumarCantidad,
      restarCantidad,
      toggleFav,
      viewDetail,
      issuserParam,
      adjust10th,
      decimal,
      saveProducto,
      styleClass,
      getStrategyImage,
      getResponsiveCard,
      PUBLICPATH,
      formatPrice,
      descuentosUnics,
    };
  },
};
</script>

<style>
@media(min-width: 576px) {
  .btn-carrito-escritorio {
    margin-left: 20%;

  }

  .precio-carrito-escritorio {
    margin-left: 25%;
  }
}


@media(max-width: 576px) {
  .precio-carrito-telefono {
    font-size: 15px;
    padding-bottom: 5px;
  }

  .btn-carrito-telefono {
    font-size: 80% !important;
  }

  .icon-carrito-telefono {
    font-size: 100% !important;
  }

  .card-product {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(0, 0, 0, .125) !important;
  }

}

.precio-descuento {
  color: rgb(238, 63, 63) !important;
}

.card-product {
  border: none !important;
  border-radius: 15px!important;
}

.card-product:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(0, 0, 0, .125) !important;
}

.card-product .card-title :hover {
  color: v-bind(styleClass) !important;
}
</style>