<template>
    <div class="w-100 mt-5 pt-5">
      <div class="row w-100 h-100 mx-0 pt-2">
       
      </div>

      <div class="container">
        <h5 class="title-client" style="color:black;">{{productoPersonalizado.titulo}}</h5>
        <hr>
        <div class="row pb-5 pt-4 p-sm-0 p-md-0 p-lg-0 text-center">
          
          <div class="col justify-content-around pb-5">
            <p>
                {{productoPersonalizado.descripcion }}
            </p>

          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import {  ref } from "vue";
export default {
  setup() {
   
    const productoPersonalizado = ref({
        titulo : 'Cancha de padel',
        descripcion: 'Disponemos de un previo con tres canchas de padel disponibles las 24 horas del dia, aaa que bien poco trabajaba'
        
    });
     return {productoPersonalizado}
  }
};
</script>
