<template>
    <div class="row justify-content-center row-marcas mb-2 mn-md-3 mb-lg-4">
      <div class="col-auto col-marca d-flex align-content-center justify-content-center p-2 p-md-3 p-lg-3" v-for="marca in marcas" :key="marca.idLogoMarca">
        <img class="img-marca" :src="marca.ruta + '/'+ marca.rutaLogo">
      </div>
    </div>
</template>

<script>
import { onBeforeMount, ref, inject } from 'vue';
export default {
    setup() {
        const $axios = inject("$axios");
        const marcas = ref({});

        onBeforeMount(
            () => {
                $axios
                    .get(`/marcas/marcasBanner`)
                    .then((result) => {
                        if (result.data.cod_respuesta == "00") {
                            marcas.value = result.data.retorno
                        }
                    });
            }
        );

        return{
            marcas
        }
    }
}




</script>
<style>
  .img-marca{
    height: 50px!important;
  }
  .col-marca{
    padding-top: 15px!important;
    padding-bottom: 15px!important;
    flex:1!important;
  }



  .row-marcas{
     display: inline-flex!important;
     border-left: 2px rgb(218, 217, 217) solid!important;
     border-top-left-radius: 10px 10px!important;
     border-bottom-left-radius: 10px 10px!important;
     border-right: 2px rgb(218, 217, 217) solid!important;
     border-top-right-radius: 10px 10px!important;
     border-bottom-right-radius: 10px 10px!important;
     border-top: 2px rgb(218, 217, 217) solid!important;
     border-bottom: 2px rgb(218, 217, 217) solid!important;
     flex-wrap: nowrap;
  }

  @media(max-width:1140px){
     .img-marca{
       height: 30px!important;

     }
  }

  @media(max-width:640px){
     .img-marca{
       height: 10px!important;
     }
  }
</style>