<template>
  <loading-data v-if="loadingData" msg="Cargando..." tipo="T" />
  <div class="container padding">
<!--     <div class="row mb-md-4 mb-lg-4 mb-0 mt-5 mt-0 mt-sm-2 mt-md-5 mt-lg-5 pt-0 pt-sm-2 pt-md-5 pt-lg-5">
      <BackButton class="align-items-start mt-3 mt-sm-1" />
    </div> -->
    <div class="row mt-5 pt-3 pt-md-5 pt-lg-5 px-md-5">
      <div class="col-12 px-md-5 text-center">
        <h2 :class="issuserParam.fuenteTitulo && issuserParam.fuenteTitulo != '-1' ? 'title-client' : ''">Mi Carrito</h2>
      </div>
      <div class="col-12 col-sm-12 px-md-5">
        <div class="card text-center text-sm-start border-0">
          <ul class="list-group">
            <li class="list-group-item" v-for="product in products" :key="product.codigo">
              <div class="row margin-right">
                <div class="col-12 col-md-7 col-sm-12">
                  <div class="card border-0">
                    <div class="row">
                      <div class="col-md-4 col-sm-4 col-4">
                        <a href="javascript:void(0)" @click="viewDetail(product)">
                          <img :src="[
                            product.imagen.includes('no-img')
                              ? '/img/new-products/no-img.jpg'
                              : product.ruta + product.imagen,
                          ]" class="img-fluid rounded-start img-cart img-card" :alt="product.imagen" style="
                            object-fit: scale-down;
                            width: 100%;
                            height: 200px;
                          " />
                        </a>
                      </div>
                      <div class="col-md-8 col-sm-8 col-8 texto-izquierda">
                        <div class="card-body pt-0 pt-md-3">

                          <h5 class="card-title ch5 subtitle-client">{{ capitalizarPalabras(product.nombreProducto) }}</h5>
                          <h5 class="card-subtitle fw-bold ch5 subtitle-client">
                            Precio: {{
                              formatMoney(issuserParam.monedalp)
                            }}{{ formatPrice(product.precioVenta, decimal) }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-5 mt-4 col-md-2 px-md-0 px-sm-0 px-0 col-sm-6 mt-md-3 botones">
                  <div
                    class="row input-group px-2 px-sm-3 input-group-sm ml-5 input-group-xs w-md-60 justify-content-center justify-content-sm-start cantbuttons ig-size">
                    <button class="btn btn-outline-secondary col-3 minusbutton input-cant d-flex" type="button"
                      @click="restarCantidad(product)">
                      <fa icon="minus" />
                    </button>
                    <input class="input-cant
                                  form-control
                                  inputcount
                                  p-sm-0
                                  text-center
                                  border-0
                                " type="number" style="
                                  width: 25%;
                                  flex: none;
                                  margin: 1px;
                                  padding: inherit;
                                " v-model="product.cantidad" @update:modelValue="updateProduct(product)" />
                    <button class="btn btn-outline-secondary col-3 plusbutton input-cant d-flex" type="button"
                      @click="sumarCantidad(product)">
                      <fa icon="plus" />
                    </button>
                  </div>
                </div>

                <div class="
                  col-7 col-sm-6 col-md-3
                  d-flex
                  align-items-end
                  flex-column
                  bd-highlight
                  mt-md-3
                  mb-xs-5
                ">
                  <div class="row w-100 text-end justify-content-end pr-md-5">
                    <div class="bd-highlight col-8 col-md-12 precio">
                      <h6 class="fw-bold ch5">
                        {{
                          formatMoney(issuserParam.monedalp)
                        }}{{ formatPrice(product.subTotal, decimal) }}
                      </h6>
                    </div>
                    <div
                      class="mt-auto p-2 bd-highlight col-4 col-md-12 align-items-end align-content-end justify-content-end al-md-b">
                      <button
                        class="btn btn-outline-danger mt-1  float-md-bottom bottom-0 margin-right px-md-2 px-lg-2 px-1 py-md-2 py-lg-2 py-1"
                        @click="deleteProduct(product.codigo)">
                        <fa icon="trash" />
                      </button>
                    </div>
                  </div>

                </div>
              </div>

            </li>
          </ul>
          <ul class="list-group border">
            <li class="list-group-item">
              <div class="row">
                <div class="text-end">
                  <h4 class="fw-bold subtitle-client">
                    Total: {{ formatMoney(issuserParam.monedalp) }}
                    {{ formatPrice(total, decimal) }}
                  </h4>
                </div>
              </div>
            </li>
          </ul>
          <div class="card-body pb-5">
            <div class="row pb-5">
              <div class="d-grid col-12 col-sm-6 text-center mt-1">
                <button class="btn btn-client-invertido btn-client-border subtitle-client" @click="router.push({ name: 'Home' })">
                  <fa icon="cart-arrow-down" /> Continuar Comprando
                </button>
              </div>
              <div class="d-grid col-12 col-sm-6 text-center mt-1">
                <button class="btn btn-client subtitle-client" @click="confirmarCompra">
                  <fa icon="shopping-cart" /> Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, watch, ref, onMounted, onUnmounted } from "vue";
import {
  calcSubTotal,
  formatPrice,
  validarProductoPesable,
  desplegarSwalProductoPesable,
  capitalizarPalabras
} from "@/composable/functions";
import router from "@/router";
/* import BackButton from "@/components/BackButton.vue"; */
import LoadingData from "@/components/LoadingData.vue";
import Swal from "sweetalert2";
export default {
  components: {
   /*  BackButton, */
    LoadingData
  },
  setup() {
    const decimal = inject("decimal");
    const cart = inject("cart");
    //    const getApiGeneric = inject("getApiGeneric");
    const postApiGeneric = inject("postApiGeneric");
    const saveProductoApp = inject("saveProducto");
    const issuserParam = inject("issuserParam");
    const styleClass = inject("styleClass");
    const products = ref([]);
    const total = ref(0);
    const loadingData = ref(true);


    const instanceCart = () => {
      if (cart.value.length <= 0) {
        return;
      }
      let url = `productos/cart`;
      let params = { idDeposito: parseInt(issuserParam.value.idDeposito), idsProductos: cart.value }
      postApiGeneric(url, params, (response) => {
        if (response.cod_respuesta == "00") {
          products.value = response.retorno;
          products.value.forEach((prod) => {
            total.value += parseFloat(prod.subTotal);
          });
          instanceTrack();
          setearDiv();
          loadingData.value = false;
        }

      })
    }

    const setearMicrodatosCarrito = () => {
      //CHECKEO QUE NO HAYA MICRDATOS INSERTADOS
      const scripts = Array.from(document.head.getElementsByTagName('script'));
      scripts.forEach(script => {
        if (script.type === "application/ld+json") {
          document.head.removeChild(script);
        }
      });

      let arrayProductosMicroData = [];

      products.value.forEach(
        (pr) => {
          let producto = {
            '@type': 'Product',
            'category': pr.google_category ? pr.google_category : "1604",
            'name': pr.nombreProducto,
            'description': pr.descripcion,
            'url': "https://pacampania.com.uy",
            'image': pr.ruta + pr.imagen,
            'offers': {
              '@type': 'Offer',
              'price': pr.precioUnitario,
              'priceCurrency': 'UYU',
              'availability': pr.stock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
            },
            'itemCondition': 'https://schema.org/NewCondition',
            'productID': pr.codigo,
          };
          arrayProductosMicroData.push(producto);
        }
      )
      let jsonLd = {
        '@context': 'https://schema.org/',
        '@type': 'ItemList',
        'name': 'Carrito',
        'description': 'Lista de productos del carrito',
        'itemListElement': arrayProductosMicroData
      }

      let script = document.createElement('script');
      script.type = "application/ld+json";
      script.textContent = JSON.stringify(jsonLd);
      //seteo los microdatos
      document.head.appendChild(script);
    }


    ///Esta funcion genera la funcion con los parametros a ejecutar para activar el evento addToCart
    const instanceTrack = () => {
      if (issuserParam.value.generaCatalogo != undefined && issuserParam.value.generaCatalogo === "S") {
        let contents = [];
        cart.value.forEach(
          (product) => {
            let prod = { id: product.id, quantity: product.cantidad };
            contents.push(prod);
          }
        );
        let objTrack = { value: total.value, currency: "UYU", contents: contents, content_type: 'product' };
        let script = "fbq('track', 'AddToCart'," + JSON.stringify(objTrack) + ");";
        eval(script);
        setearMicrodatosCarrito();
      }
    };

    /// esta funcion setea el div que relaciona el evento addToCart con el pixel de meta
    const setearDiv = () => {
      /// genera el div que vincula los microdatos con el pixel
      if (issuserParam.value.generaCatalogo != undefined && issuserParam.value.generaCatalogo === "S") {
        let div = document.createElement('div');
        div.id = 'divPixel';
        div.setAttribute('itemprop', 'interactionCount');
        div.setAttribute('content', '1');
        div.setAttribute('data-event', 'add_to_cart');
        document.head.appendChild(div);
      }
    }

    if (issuserParam.value.idLP) {
      instanceCart();
      loadingData.value = false;
    }

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instanceCart();
        loadingData.value = false;
      }
    );

    const updateProduct = (prod) => {
      if (!prod.cantidad) {
        return;
      }
      if (!validarProductoPesable(prod)) {
        desplegarSwalProductoPesable("La cantidad de este producto no puede ser fraccionada", "Para comprar este producto debe ingresar uan cantidad como número entero, ej:1");
        prod.cantidad = 1;
        return;
      }
      let cantidad = prod.cantidad;
      let cantidadResult = saveProducto(prod).cantidad;
      if (cantidadResult !== cantidad) {
        prod.cantidad = cantidadResult;
        updateProduct(prod);
      }
      prod.subTotal = calcSubTotal(prod.precioVenta, prod.cantidad, decimal);
      calcTotal();
      instanceTrack();
    };

    const saveProducto = (prod) => {
      return saveProductoApp(prod, null, true);
    };

    const sumarCantidad = (product) => {
      product.cantidad = parseFloat(product.cantidad) + 1;
      updateProduct(product);
      return product;
    };
    const restarCantidad = (product) => {
      product.cantidad =
        product.cantidad - 1 ? parseFloat(product.cantidad) - 1 : 1;
      updateProduct(product);
      return product;
    };

    const deleteProduct = (codigo) => {
      let index = cart.value.findIndex((product) => product.id === codigo);
      cart.value.splice(index, 1);
      index = products.value.findIndex((product) => product.codigo === codigo);
      products.value.splice(index, 1);
      saveProducto();
      calcTotal();
      instanceTrack();
    };

    const calcTotal = () => {
      total.value = products.value.reduce((acc, curr) => {
        return acc + parseFloat(curr.subTotal);
      }, 0);
      return total.value;
    };

    const viewDetail = (producto) => {
      router.push({ name: "DetailArticle", params: { id: producto.idProductoBase && producto.idProductoBase != null ? producto.idProductoBase : producto.codigo} });
    };

    const confirmarCompra = () => {
      if (cart.value.length <= 0) {
        Swal.fire({
          title: "Debe agregar un producto al carrito para continuar",
          icon: "warning",
          confirmButtonColor: styleClass.value
        });
        return;
      }
      router.push({ name: 'Checkout' });
    }

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    onUnmounted(
      () => {
        // CHECKEO QUE NO HAYA VINCULACIONES CON EL PIXEL
        if (document.getElementById("divPixel") != undefined) {
          document.head.removeChild(document.getElementById("divPixel"));
        }
      }
    );

    return {
      confirmarCompra,
      capitalizarPalabras,
      instanceTrack,
      products,
      issuserParam,
      sumarCantidad,
      restarCantidad,
      updateProduct,
      total,
      deleteProduct,
      router,
      decimal,
      styleClass,
      viewDetail,
      formatPrice,
      loadingData
    };
  },
};
</script>

<style>
@media(max-width: 576px) {
  .botones {

    padding-right: 0% !important;
  }

  .img-cart {
    height: auto !important;
  }

  .ig-size {
    padding-right: 0% !important;
  }

  .ch5 {
    font-size: small !important;
  }

  .texto-izquierda {
    margin-top: 0% !important;
    padding-top: 0% !important;
    text-align: left !important;
    height: 0px !important;
  }

  .precio {
    margin-top: 18% !important;
    margin-right: 0% !important;
    padding-top: 0% !important;
  }
}

@media(min-width: 576px) {
  .al-md-b {
    align-items: bottom !important;
    bottom: 0% !important;
  }

  .margin-right {
    margin-right: 1% !important;
  }
}
</style>
