<template>
  <loading-data v-if="loadingData" msg="Cargando..." tipo="T" />
  <div class="container padding cont h-100 pt-5">
    <div class="row" v-if="brandLogo.rutaLogo">
      <div class="col text-center">
        <img class="img-fluid" :src="[brandLogo.ruta + brandLogo.rutaLogo]" />
      </div>
    </div>
    <div class="row mt-2 h-100 pb-5 mb-1 pt-5" id="containerproductodetalle">
     <!--  <div class="row pt-3 align-items-start text-left pb-1" style="padding-left: 10px!important;">
        <BackButton class="align-items-start" />
      </div> -->
      <div class="card border body-responsive">
        <div class="row">
          <div class="col-sm-12 col-md-6 text-center align-self-center mt-3 mb-3">
            <div id="carouselDetailArticle" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button type="button" class="item" data-bs-target="#carouselDetailArticle"
                  v-for="(item, index) in urlsCarousel" :key="item.codigo" :id="item.codigo" :data-bs-slide-to="index"
                  :class="index === 0 ? 'active' : ''" :aria-current="index === 0 ? 'true' : 'false'"
                  :aria-label="item.codigo"></button>
              </div>
              <div class="carousel-inner carousel-detail">
                <div class="carousel-item item image-container" data-bs-interval="4000" :class="index === 0 ? 'active' : ''" 
                  v-for="(item, index) in urlsCarousel" :id="'img_' + item.codigo" :key="item.codigo"
                  style="min-width: 100%;">
                   <img :src="item.ruta + item.imagen" class="mx-auto mw-100 imgDetail mx-auto my-auto py-auto" style="object-fit: scale-down;" :alt="item.imagen" /> 
                </div>
              </div>
              <button class="carousel-control-prev btn-carousel" type="button" data-bs-target="#carouselDetailArticle"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon " aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next btn-carousel" type="button" data-bs-target="#carouselDetailArticle"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="card-body">
              <h2 id="nombreProductoDetail" class="card-title" :class="issuserParam.fuenteTitulo ? 'title-client' : ''">{{ producto.nombreProducto }}</h2>
              <div v-if="producto.precioUnitario && issuserParam.deshabilitarPrecios != 'S'">
                <div v-if="descuentosUnics.length">
                  <div class="row" v-for="descuento in descuentosUnics" :key="descuento.idBanco">

                    <div class="col-6 p-0">
                      <img style="width: 40px;" :src="'/img/mediospago/' + descuento.logoBanco">
                      <label class="card-subtitle margenCero pb-0 pt-2 precio-descuento fs-5"
                        id="precioProductoDescuento">
                        {{ formatMoney(issuserParam.monedalp) }}{{ producto.precioDescuento }}
                      </label>
                    </div>
                  </div>

                </div>
                <h3 class="card-subtitle margenCero text-muted" id="precioProducto" :class="issuserParam.fuentePagina && issuserParam.fuentePagina != '-1' ? 'pageFont' : ''">
                  {{ formatMoney(issuserParam.monedalp) }} {{ producto.precioVenta }}
                  <s v-if="parseFloat(producto.pctDescuento)">{{ formatMoney(issuserParam.monedalp) }}
                    {{ parseFloat(producto.precioUnitario).toFixed(decimal) }}</s>
                </h3>
              </div>
              <div id="contenedorbotonescolorTEL">
                <a v-for="talle in tallesProductos" :key="talle.idTalle" class="btnTalle mt-2 btn me-1"
                  :class="[talle.clase ? talle.clase : 'btn-secondary']" v-bind:id="'talle_' + talle.ordinal"
                  @click="loadTalleColores(talle, talle.idTalle)">{{ talle.nombreTalle }}</a>
                <div id="li-listaColores" class="row mt-1 mb-1">
                  <div class="col-1 d-grid gap-2" v-for="color in tallesColores.colColores" :key="color.idColor">
                    <button class="btnColor mx-sm-3 btnColor-Telefono btn btn-lg" :style="[
                      `background-color:${color.codigoColor};`,
                      'border: 1px solid rgb(141 137 125);height:30px;',
                    ]" v-bind:id="'color_' + color.ordinal" :class="[color.class ? color.class : '']"
                      @click="saveColor(color)"></button>
                  </div>
                </div>
              </div>
              <p v-if="producto.descripcion || producto.descripcionBase" id="descripcionProducto" :class="issuserParam.fuentePagina && issuserParam.fuentePagina != '-1' ? 'pageFont' : ''"
                class="card-text textoProducto pt-3">{{
                  producto.descripcionBase ? producto.descripcionBase : producto.descripcion
                }}</p>
              <p v-if="producto.desAdic" id="desAdicProducto" class="card-text textoProducto"
              :class="issuserParam.fuentePagina && issuserParam.fuentePagina != '-1' ? 'pageFont' : ''"
              v-html="producto.desAdic"></p>

              <p v-if="issuserParam.generaCatalogo != undefined && issuserParam.generaCatalogo === 'S' && getMarca(producto) !='' " :class="issuserParam.fuentePagina && issuserParam.fuentePagina != '-1' ? 'pageFont' : ''">
                Marca: {{ getMarca(producto) }}
              </p>
              <p class="text-muted py-0 my-0"
                v-if="issuserParam.generaCatalogo != undefined && issuserParam.generaCatalogo === 'S'" :class="issuserParam.fuentePagina && issuserParam.fuentePagina != '-1' ? 'pageFont' : ''">
                Nuevo
              </p>
              <div id="contenedorbotonescolorPC">
                <a v-for="talle in tallesProductos" :key="talle.idTalle" class="btnTalle mt-3 btn me-1"
                  :class="[talle.clase ? talle.clase : 'btn-secondary']" v-bind:id="'talle_' + talle.ordinal"
                  @click="loadTalleColores(talle, talle.idTalle)">{{ talle.nombreTalle }}</a>
                <div id="li-listaColores" class="row mt-3">
                  <div class="col-1 d-grid gap-2" v-for="color in tallesColores.colColores" :key="color.idColor">
                    <button class="btnColor btnColor-Telefono btn-lg" :style="[
                      `background-color:${color.codigoColor};`,
                      'border: 1px solid rgb(141 137 125);height:30px;',
                    ]" v-bind:id="'color_' + color.ordinal"
                      :class="[colorSelected.idColor === color.idColor ? colorSelected.class : '']"
                      @click="saveColor(color)" ref="botoncito"></button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="issuserParam.deshabilitarCarrito != 'S'" class="card-footer bg-transparent border-light">
              <p id="stockProducto" class="card-text textoProducto text-muted text-sm px-1"
                v-if="producto.stock && issuserParam.ocultarStockProductos != 2">
                Disponible(s): {{ parseInt(producto.stock) }}</p>
              <div class="row pb-4" v-if="producto.pesable && producto.pesable === 'S'">
                <p class="text-danger">El importe de este producto puede variar ligeramente al momento de ser facturado.
                </p>
                <div class="col-2">
                  <h6>Unidad: kg</h6>
                </div>
                <div class="col-1 p-0 m-0">
                  <button class="btn p-0 m-0" type="button" @click="desplegarInfoUnidad">
                    <fa icon="exclamation-circle" />
                  </button>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-4 px-sm-0 px-md-0 px-lg-3 px-0">
                  <div class="input-group cantbuttons">
                    <button class="btn btn-outline-secondary minusbutton" style="z-index: 0" type="button"
                      @click="restarCantidad">
                      <fa icon="minus" />
                    </button>
                    <input class="form-control inputcount text-center" type="number" v-model="cantidad"
                      id="cantidadDetalle" />
                    <button class="btn btn-outline-secondary plusbutton" style="z-index: 0" type="button"
                      @click="sumarCantidad">
                      <fa icon="plus" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-3"
                v-if="(issuserParam.tipoMarketTienda == null || issuserParam.tipoMarketTienda == 0) && issuserParam.deshabilitarCarrito != 'S'">
                <div class="col">
                  <button class="btn btn-client" @click="saveProducto()">
                    <fa icon="cart-plus" />
                    <span id="BtnAgregarCarritoDetail"
                      v-html="producto.stock != null && producto.stock <= 0 ? 'Sin Stock' : 'Agregar'"></span>
                  </button>
                </div>
              </div>
              <div class="row mt-3 itemsCenter"
                v-if="(issuserParam.tipoMarketTienda != null && issuserParam.tipoMarketTienda != 0) && issuserParam.deshabilitarCarrito != 'S'">
                <div class="col">
                  <button class="btn btn-client ml-3 subtitle-client" @click="saveProducto()">
                    <fa icon="cart-plus" />
                    <span id="BtnAgregarCarritoDetail">Agregar</span>
                  </button>
                </div>
              </div>
              <div hidden class="row mt-1 text-center">
                <div class="row">
                  <div class="col">
                    <a id="sharefacebook" class="btn btn-primary mx-1" title="Compartir en Facebook" target="_blank"
                      href="#">
                      <fa :icon="['fab', 'facebook']" />
                    </a>
                    <a id="sharewpp" class="btn btn-success mx-1" target="_blank" title="Compartir en WhatsApp" href="#">
                      <fa :icon="['fab', 'whatsapp']" />
                    </a>
                    <a id="sharetw" class="btn btn-primary mx-1" target="_blank" title="Compartir en Twitter" href="#">
                      <fa :icon="['fab', 'twitter']" />
                    </a>
                    <a id="sharemail" class="btn btn-info text-white mx-1" target="_blank" title="Enviar por mail"
                      href="#">
                      <fa icon="envelope" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row position-absolute bottom-0 end-0 px-2">
              <p v-if="producto.codigo" class="card-text mt-2 textoProducto text-muted">
                <small>
                  Art: {{ producto.codigo }}
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-5 pb-md-0 pb-lg-0">
      <vue-easy-lightbox :visible="visible" :imgs="imgs" :index="index" @hide="handleHideImg"></vue-easy-lightbox>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { inject, ref, watch, onMounted, computed, onUnmounted } from "vue";

import { calcSalePrice, adjust10th, capitalizarPalabras, validarProductoPesable, desplegarSwalProductoPesable, zoomChild, removeZoom} from "@/composable/functions";
import Swal from "sweetalert2";
//import BackButton from "@/components/BackButton.vue";
import router from "@/router";


import LoadingData from "@/components/LoadingData.vue";

export default {
  components: {
   // BackButton,
    LoadingData
  },
  setup() {
    const decimal = inject("decimal");
    const route = useRoute();
    const producto = ref({});
    const getApiGeneric = inject("getApiGeneric");
    const issuserParam = inject("issuserParam");
    const saveProductoApp = inject("saveProducto");
    //  const EventoMC = inject("eventoMC");

    let imgs = ref([]);

    let visible = ref(false);
    let brandLogo = {};
    const urlsCarousel = ref([]);
    let cantidad = ref(1);
    const tallesProductos = ref([]);
    const tallesColores = ref({ colColores: [], clase: "btn-default" });
    const colorSelected = ref({});
    const talleSelected = ref({});
    const currentIndex = ref(0);
    const index = ref(0);
    const styleClass = inject("styleClass");
    const loadingData = ref(true);
    const descuentos = inject("descuentos");
    const prodsDetalle = ref([]);
    const containers = ref([]);

    const descuentosUnics = computed(() => {
      let arrayDescuentos = [];
      if (!descuentos.value) {
        return arrayDescuentos;
      }
      arrayDescuentos = descuentos.value.filter((item, index) => {
        return descuentos.value.indexOf(descuentos.value.find((desc) => desc.idBanco === item.idBanco)) === index;
      })
      return arrayDescuentos;
    });

    const sumarCantidad = () => {
      return (cantidad.value = parseFloat(cantidad.value) + 1);
    };

    const restarCantidad = () => {
      return (cantidad.value =
        cantidad.value - 1 ? parseFloat(cantidad.value) - 1 : 1);
    };

    const showImg = (i) => {
      index.value = i;
      visible.value = true;
    };
    const handleHideImg = () => {
      visible.value = false;
    };

    const desplegarInfoUnidad = () => {
      Swal.fire({
        title: "Si desea ingresar una cantidad menor a 1kg como 500g, debe ingresar 0.5",
        icon: "warning",
        confirmButtonColor: styleClass.value
      });
    }

    const getMarca = (producto) =>{
      let marca = "";
        if(producto.marca != undefined && producto.marca != null && producto.marca != ""){
          marca = producto.marca;
        }
        return marca;
    }

    const activarZoom = () => {
      if(containers.value.length >0){
        removeZoom(containers.value)
      }
      setTimeout(() => {
        if(issuserParam.value != undefined && issuserParam.value.zoomImgDetalle && issuserParam.value.zoomImgDetalle != "S"){
          return;
        }
        else if (issuserParam.value != undefined && issuserParam.value.zoomImgDetalle && issuserParam.value.zoomImgDetalle == "S" 
        && document.querySelectorAll('.carousel-item.item.image-container.active') != undefined && document.querySelectorAll('.carousel-item.item.image-container.active').length > 0) {
          const zoomContainers = document.querySelectorAll('.carousel-item.item.image-container');
          containers.value = zoomContainers;
          zoomChild(zoomContainers, 2)
          return;
        } 

       }, 1000);
    }

    const instanceProducto = () => {

      let urlapi = `productos/detalle`;
      let param = { producto: route.params.id, lp: issuserParam.value.idLP, deposito: issuserParam.value.idDeposito };


      //Obtenemos el producto
      getApiGeneric(urlapi, param, (response) => {

        //SI tiene plugin tienda llega un array de productos
        if (response.retorno.colReturn && response.retorno.colReturn instanceof Array) {
          prodsDetalle.value = response.retorno.colReturn;
          tallesProductos.value = response.retorno.talles;
          loadTalleColores(tallesProductos.value[0], tallesProductos.value[0].idTalle);
          return;
        }

        producto.value = response.retorno;
        producto.value.nombreProducto = capitalizarPalabras(producto.value.nombreProducto)
        actualizarPrecioVenta();
        setearImagenesCarousel();
        loadingData.value = false;
        setearMicrodatos();

      });
      // }
      //obtenemos la marca
      /*       getApiGeneric(
              `productos/marca`,
              { producto: route.params.id },
              (prod) => {
                brandLogo = { ruta: prod.ruta, rutaLogo: prod.rutaLogo };
              }
            ); */
    };

    //esta funcion calcula el precio de venta del producto en base al precio unitario, el porcentaje de descuento y los decimales configurados
    const actualizarPrecioVenta = () => {
      producto.value.precioVenta = (
        parseFloat(
          calcSalePrice(
            producto.value.precioUnitario,
            producto.value.pctDescuento,
            decimal
          )
        ) +
        adjust10th(
          issuserParam.value.configRedondeoDecimas,
          calcSalePrice(
            producto.value.precioUnitario,
            producto.value.pctDescuento,
            decimal
          ),
          decimal
        )
      ).toFixed(decimal);
    }

    //esta funcion calcula el precio de venta del producto en base al precio unitario, el porcentaje de descuento y los decimales configurados
    const actualizarPrecioPlanDescuento = () => {
      if (descuentosUnics.value.length > 0) {
        let descuento = parseFloat(producto.value.pctDescuento) + parseFloat(descuentosUnics.value[0].descBanco) + parseFloat(descuentosUnics.value[0].descComercio);
        producto.value.precioDescuento = (
          parseFloat(
            calcSalePrice(
              producto.value.precioUnitario,
              descuento,
              decimal
            )
          )
        ).toFixed(decimal);
      }
    }


    //Esta funcion coloca las imagenes en el carousel, si no hay imagenes coloca "/no-img.jpg"
    const setearImagenesCarousel = () => {
      urlsCarousel.value = producto.value.imagenes && producto.value.imagenes.retorno.length ? producto.value.imagenes.retorno : [{ ruta: "/img/new-products", imagen: "/no-img.jpg" }];
      imgs.value.push(
        !producto.value.imagen || producto.value.imagen.includes("no-img")
          ? "/img/new-products/no-img.jpg"
          : producto.value.ruta + producto.value.imagen
      );
      loadingData.value = false;
    }

    //Esta funcion me trae los colores del talle seleccionado
    //Tambien le da la clase active cuando el boton es clickeado
    const loadTalleColores = (talle, idTalle) => {
      tallesColores.value.clase = "";
      tallesColores.value = talle;
      tallesColores.value.clase = "btn-dark";
      talleSelected.value = idTalle;

      /// si ya hay un color seleccionado (esto no sucede la primera vez que se llama a esta funcion) y existe un producto con este talle y color
      if (Object.keys(colorSelected.value).length > 0 && talleTieneColor(colorSelected.value)) {

        //llamamos al producto con el talle y el color seleccionado
        saveColor(colorSelected.value);

        //si hay un color seleccionado pero no existe un producto con este talle y color
      } else if (Object.keys(colorSelected.value).length > 0) {

        //le avismos al usuario
        Swal.fire({
          title: "Por el momento el producto no se encuentra en talle " + tallesColores.value.nombreTalle + " y color " + colorSelected.value.nombreColor.toLowerCase(),
          icon: "warning",
          confirmButtonColor: styleClass.value,
        });
        //llamamos al producto de este talle con el primer color que exista
        saveColor();
      } else {
        saveColor();
      }
    };


    //esta funcion invoca el detalle del producto con el talle y color seleccionado(si no hay un color llama al primer color del talle seleccionado)
    // si se le pasa un color como parametro la funcion ademas le da la clase active al boton con este color
    const saveColor = (color) => {
      let idColor;
      if (color) {
        colorSelected.value = color;
        idColor = colorSelected.value.idColor;
      } else {
        colorSelected.value = tallesColores.value.colColores[0];
        idColor = tallesColores.value.colColores[0].idColor
      }
      colorSelected.value.class = "";
      colorSelected.value.class = "border-2 borderSelected";
      setProducto(idColor);
    };

    //Esta funcion es para averiguar si existe un color en un talle
    const talleTieneColor = (color) => {
      const encontrado = tallesColores.value.colColores.find(talleColor => talleColor.idColor === color.idColor);
      return !!encontrado; // convierte el objeto encontrado en true o false
    }

    const setProducto = (idColor) => {
      producto.value = prodsDetalle.value.find((prod) => { return prod.idColor == idColor && prod.idTalle == talleSelected.value });
      producto.value.nombreProducto = capitalizarPalabras(producto.value.nombreBase);
      actualizarPrecioVenta();
      actualizarPrecioPlanDescuento();
      setearImagenesCarousel();
      setearMicrodatos();
    }

    if (issuserParam.value.idDeposito) {
      instanceProducto();
    }


    /// esta funcion setea los microdatos y activa el evento que van a ser tomados or la pataforma de meta para generar el catalogo de productos para las publicidades de fb e instagram
    const setearMicrodatos = () => {
      if (issuserParam.value.generaCatalogo == undefined || (issuserParam.value.generaCatalogo != undefined && issuserParam.value.generaCatalogo != "S")) {
        return;
      }

      //CHECKEO QUE NO HAYA MICRDATOS INSERTADOS
      const scripts = Array.from(document.head.getElementsByTagName('script'));
      scripts.forEach(script => {
        if (script.type === "application/ld+json") {
          document.head.removeChild(script);
        }
      });

      let script = document.createElement('script');
      script.type = "application/ld+json";
      script.textContent = JSON.stringify(jsonLd.value);
      //seteo los microdatos
      document.head.appendChild(script);

      // CHECKEO QUE NO HAYA VINCULACIONES ANTERIORES CON EL PIXEL
      if (document.getElementById("divPixel") != undefined) {
        document.head.removeChild(document.getElementById("divPixel"));
      }

      /// genera el div que vincula los microdatos con el pixel
      let div = document.createElement('div');
      div.id = 'divPixel';
      div.setAttribute('itemprop', 'interactionCount');
      div.setAttribute('content', '1');
      div.setAttribute('data-event', 'view_content');

      // Agregar el div al documento
      //   document.head.appendChild(div);

      //let pixelScript = extraerCodigoScript(FPixel.value)
      let pixelScript = "fbq('track', 'ViewContent', {value:" + producto.value.precioVenta + ", currency: 'UYU', content_ids: ['" + producto.value.codigo + "'], content_type: 'product'})";
      eval(pixelScript);
    };

    watch(() => urlsCarousel.value,
      //perdon x hacer esto me duele un monton pero no encontre otra solucion, luego lo voy a hacer bien :(
      (newParams) => {
        if (!newParams) return;
        if (newParams.length > 1) {
          let elementos = document.querySelectorAll('.active');
          for (let i = 0; i < elementos.length; i++) {
            elementos[i].classList.remove('active');
          }
        }

        let item = document.getElementById(urlsCarousel.value[0].codigo);
        let img = document.getElementById('img_' + urlsCarousel.value[0].codigo);
        if (item != null && img != null) {
          item.classList.add('active');
          img.classList.add('active');
        }
        activarZoom(); 
      }
    );




    const saveProducto = () => {
      if (issuserParam.value.tipoMarketTienda != null && issuserParam.value.tipoMarketTienda != 0) {
        if (!colorSelected.value.idColor || !tallesColores.value.idTalle) {
          Swal.fire('Debe seleccionar un talle y color.', '', 'warning');
          return;
        }
        producto.value.nombreTalle = tallesColores.value.nombreTalle;
        producto.value.nombreColor = colorSelected.value.nombreColor;
      }
      producto.value.cantidad = cantidad.value;
      cantidad.value =saveProductoApp(producto.value, () => {
        router.push({ name: "Cart" });
      }).cantidad;
    };

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instanceProducto();
        activarZoom(); 
      }
    );

    watch(
      () => route.params.id,
      (newParams) => {
        if (!newParams) return;
        colorSelected.value = {}
        instanceProducto();
      }
    );


    watch(
      () => cantidad.value,
      (newParams) => {
        if (!newParams) return;
        producto.value.cantidad = cantidad.value;
        if (!validarProductoPesable(producto.value)) {
          desplegarSwalProductoPesable("La cantidad de este producto no puede ser fraccionada", "Para comprar este producto debe ingresar una cantidad como número entero, ej:1");
          cantidad.value = 1;
        }
      }
    );

    const jsonLd = computed(() => {
      return {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        'name': capitalizarPalabras(producto.value.nombreProducto),
        'description': capitalizarPalabras(producto.value.descripcion),
        'url': window.location.href,
        'image': urlsCarousel.value[0].ruta + urlsCarousel.value[0].imagen,
        "brand": {
          "@type": "Brand",
          "name": producto.value.marca ? producto.value.marca : ""
        },
        'offers': {
          '@type': 'Offer',
          'price': parseFloat(producto.value.precioVenta).toFixed(decimal),
          'priceCurrency': issuserParam.value.monedalp,
          'availability': producto.value.stock > 0 ? 'https://schema.org/InStock' : "https://schema.org/OutOfStock",
        },
        'itemCondition': 'https://schema.org/NewCondition',
        'productID': producto.value.codigo,
        "category": producto.value.google_category ? producto.value.google_category : "1604",
        "sku": producto.value.codigo
      }
    })

    onMounted(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      activarZoom();   
    });
    onUnmounted(
      () => {
        // CHECKEO QUE NO HAYA VINCULACIONES CON EL PIXEL
        if (document.getElementById("divPixel") != undefined) {
          document.head.removeChild(document.getElementById("divPixel"));
        }
      }
    )

    return {
      getMarca,
      capitalizarPalabras,
      desplegarInfoUnidad,
      jsonLd,
      producto,
      issuserParam,
      showImg,
      handleHideImg,
      index,
      visible,
      imgs,
      brandLogo,
      cantidad,
      sumarCantidad,
      restarCantidad,
      saveProducto,
      decimal,
      tallesProductos,
      loadTalleColores,
      tallesColores,
      saveColor,
      urlsCarousel,
      colorSelected,
      currentIndex,
      loadingData,
      descuentosUnics
    };
  }
};
</script>

<style>
.btn-carousel {
  background: rgb(0, 0, 0) !important;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.3883928571428571) 0%, rgba(255, 255, 255, 0) 11%) !important;
 /*  height: 20%!important;
  align-self: center !important; */
}

/* .image-container {
  overflow: hidden;
  position: relative;
  cursor: crosshair;
}

.image-container img {
 /* width: 100%; 
 transition: transform 0.5s ease, left 0.5s ease, top 0.5s ease;
  transform-origin: center center;
}

.image-container:hover img {
  transform: scale(2.1); 
}

#carouselDetailArticle{
  align-items: center!important;
  justify-content: center !important;
  display: flex!important;
} */

@media(max-width: 576px) {

  .itemsCenter {
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }

  .carousel-detail img {
    max-height: 430px !important;
    min-height: 430px !important;
  }

  .carousel-detail .imgDetail {
    max-height: 410px !important;
    min-height: 410px !important;
  }

  #contenedorbotonescolorPC {
    display: none !important;
  }

  .btnColor {
    margin-top: 15% !important;
    padding: 10px !important;
  }

  #containerproductodetalle {
    margin-top: 0% !important;
    padding-top: 0% !important;
  }

}


@media(min-width: 576px) {
  #contenedorbotonescolorTEL {
    display: none !important;
  }



  #relleno {
    min-height: 230px !important;
  }

  .carousel-detail img {
    max-height: 550px !important;
    min-height: 550px !important;
  }

  .carousel-detail .imgDetail {
    max-height: 550px !important;
    min-height: 550px !important;
  }

  .borderSelected {
    box-shadow: rgba(5, 118, 247, 0.3) 0px 0px 0px 3px !important;
  }
}
</style>