<template>
  <div class="modal fade pb-5" id="payExpressModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" id="buttonModalCloseStatic" data-bs-dismiss="modal"
            @click="volverPayExpress" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div id="iframePago" style="height: 800px"></div>
          <div class="row mt-3">

            <div id="form-error" v-if="Object.keys(errorFE).length > 0">
              <div class="card">
                <h5 class="card-header text-danger">
                  <i class="fas fa-times-circle"></i>
                   Atención
                </h5>
                <div class="card-body">
                  <h5 class="card-title">
                    {{ errorFE.mensaje }}
                  </h5>
                  <hr />
                  <div>
                    <label>Id. Transacción: </label>
                    <label>{{ errorFE.cod }}</label>
                  </div>
                  <a href="javascript:void(0);" @click="volverPayExpress" class="btn btn-danger">Volver</a>
                </div>
              </div>
            </div>
            <div id="form-fault" v-if="Object.keys(errorPayExpress).length > 0">
              <div class="card">
                <h5 class="card-header text-danger">
                  <i class="fas fa-info-circle"></i>
                  No se pudo completar la compra.
                </h5>
                <div class="card-body">
                  <h5 id="trans-info-msg">{{ errorPayExpress.issuerResponseMessage }}</h5>
                  <h6 id="trans-info-details">{{ errorPayExpress.issuerAditionalData }}</h6>
                  <br />

                  <div>
                    <label>Id. Transacción: </label>
                    <label id="trans-info-authid">{{ errorPayExpress.issuerTransactionId }}</label>
                  </div>
                  <div>
                    <label>Codigo de Respuesta: </label>
                    <label id="trans-info-respcode">{{ errorPayExpress.issuerResponseCode }}</label>
                  </div>

                  <br />

                  <div>
                    <label>Tarjeta: </label>
                    <label id="trans-info-cardnumber">{{ errorPayExpress.issuerCard }}</label>
                  </div>
                  <div>
                    <label>Vencimiento: </label>
                    <label id="trans-info-cardexpdate">{{ errorPayExpress.issuerCardVto }}</label>
                  </div>
                  <hr />
                  <a href="#" class="btn btn-danger" @click="volverPayExpress">Volver</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" id="closeModalOpen" style="display: none">
          <a role="button" class="btn btn-secondary" data-bs-dismiss="modal" href="javascript:void(0);"
            @click="volverPayExpress">
            Volver
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, watch, ref } from "vue";
import { formatPricePayexpress, randomTransactionId, getFormattedDate } from "@/composable/functions";
import { Modal } from "bootstrap";
import router from "@/router";

export default {
  setup(props, { expose }) {
    const postApiGeneric = inject("postApiGeneric");
    const issuserParam = inject("issuserParam");
    const decimal = inject("decimal");
    const payExpressSelected = inject("payExpressSelected");
    const computedTotalCheckout = inject("computedTotalCheckout");
    const totalCheckout = inject("totalCheckout");
    const products = inject("productsCheckout");
    const styleClass = inject("styleClass");
    const precioEnvio = inject("precioEnvioCheckout");
    const cliente = inject("cliente");
    const pedidoFE = inject("pedidoFE");
    const datos = inject("datos");
    const objFactura = inject("objfactura");
    const envioPedido = inject("objEnvioPedido");
    const totalDesc = inject("totalDesc");
    const computedTotalDesc = inject("computedTotalDesc");
    const errorFE = ref({});
    const errorPayExpress = ref({});
    let modal;

    const instancePayExpress = () => {
      const scripts = [

        `https://sistemas.payexpress.com.uy/v1/gs-checkout-frontend/api.js`,
         `https://sistemas.payexpress.com.uy/v1/lib/jsencrypt.js`   
/*       `https://testing.payexpress.com.uy/v1/gs-checkout-frontend/api.js`,
         `https://testing.payexpress.com.uy/v1/lib/jsencrypt.js`,  */


      ];

      scripts.forEach((script) => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    };

    if (issuserParam.value.url_Payexpress && !window.GSCheckOut) {   
        instancePayExpress();    
    }

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instancePayExpress();
      }
    );

    const validarParams = () => {
      let paramsValidos = true;
      if (issuserParam.value.url_Payexpress == ""
        || issuserParam.value.keyPayExpress == ""
        || issuserParam.value.monedalp == ""
        || !payExpressSelected.value.tipo
      ) {
        paramsValidos = false;
      }
      return paramsValidos;
    }



    const postPurchase = (objPayExpress, factura, objEnvioPedido) => {
      postApiGeneric(
        "pasarelas/payExpress",
        objPayExpress,
        (data) => {
          switch (data.Cod) {
            case 200:
              document.getElementById("buttonModalCloseStatic").style.display = "none";
              if (
                parseInt(data.Mensaje.issuerResponse.issuerResponseCode) == 0
              ) {

                pedidoFE.value = data.Mensaje;
                datos.value = data;
                
                objFactura.value = objPayExpress;
                envioPedido.value = objEnvioPedido;
                modal.hide();
                router.push({ name: "CompletePayExpress" });

              } else {
                document.getElementById("iframePago").style.display = "none";
                errorPayExpress.value.responseCode = data.Mensaje;
              }
              break;
            case 500:
              document.getElementById("iframePago").style.display = "none";
              errorFE.value.mensaje = data.Mensaje;
              errorFE.value.cod = objEnvioPedido.nroInterno;
              break;
            default:
              document.getElementById("iframePago").style.display = "none";
              errorFE.value.mensaje = "Ha habido un problema,Vuelva a intentar. si el error persiste comuníquese con el proveedor ofreciendo el siguiente Id de transacción:";
              errorFE.value.cod = objEnvioPedido.nroInterno;
              break;
          }
        }
      );

    }

    const cargarPayExpress = () => {
      try {
        if (!validarParams()) {
          throw new Error("Parametros de payExpress invalidos");
        }

        modal = new Modal(document.getElementById("payExpressModal"));
        modal.show();

        window.GSCheckOut.load(
          "iframePago",
          `${issuserParam.value.url_Payexpress}?sello=${payExpressSelected.value.tipo
          }&apikey=${issuserParam.value.keyPayExpress
          }&amount=${computedTotalDesc.value > 0 ? computedTotalDesc.value.toFixed(2)
            .replace(".", "") : computedTotalCheckout.value
              .toFixed(2)
              .replace(".", "")}&currency=${issuserParam.value.monedalp}&cuotas=${cliente.cuotas
          }&name=${cliente.nombre}&email=${cliente.email}&doc=${cliente.documentoReceptor
          }`
        );

        window.GSCheckOut.Bind("tokenCreated", function (trozo) {
           let mensaje = "lo que me devuelven "+ trozo;
          Object.freeze(trozo);
          let objPayExpress = {};       
          objPayExpress.trozo = trozo;
          objPayExpress.transactionId = randomTransactionId();
          objPayExpress.amount = computedTotalDesc.value > 0 ? computedTotalDesc.value.toFixed(2).replace(".", "") : computedTotalCheckout.value.toFixed(2).replace(".", "");
          objPayExpress.currencyCode = issuserParam.value.monedalp;
          objPayExpress.cuotas = cliente.cuotas;
          objPayExpress.fechaLimitePago = getFormattedDate();
          objPayExpress.consumidorFinal = "1";
          objPayExpress.bin = payExpressSelected.value.bin ? payExpressSelected.value.bin : null;
          let factura = {};
          factura.pais = "UY";
          factura.ciudad = cliente.ciudad;
          factura.departamento = cliente.departamento;
          factura.domicilio = cliente.direccion;
          factura.nombre = cliente.nombre;
          factura.tipoDocumento = cliente.tipoDocumentoReceptor;
          factura.documento = cliente.documentoReceptor;
          factura.montoGravado = computedTotalDesc.value > 0 ? computedTotalDesc.value.toFixed(2).replace(".", "") : computedTotalCheckout.value.toFixed(2).replace(".", "");
          factura.montoPagar = computedTotalDesc.value > 0 ? computedTotalDesc.value.toFixed(2).replace(".", "") : computedTotalCheckout.value.toFixed(2).replace(".", "");
          factura.montoTotal = computedTotalDesc.value > 0 ? computedTotalDesc.value.toFixed(2).replace(".", "") : computedTotalCheckout.value.toFixed(2).replace(".", "");
          factura.numero = "1";
          factura.serie = "A";
          factura.tipoComprobante = "101";
          factura.tipoMoneda = issuserParam.value.monedalp;
          factura.tipoOperacion = "C";
          factura.fechaVencimiento = getFormattedDate();
          objPayExpress.factura = factura;

          let idPrecioEnvio = precioEnvio.value.idPrecio;
          let carritoDD = [
            { variable: "moneda", variableValor: issuserParam.value.monedalp },
          ];
          let objEnvioPedido = {};
          objEnvioPedido.nombrePedido = cliente.nombre;
          objEnvioPedido.direccionPedido = cliente.direccion;
          objEnvioPedido.ciudadPedido = cliente.ciudad;
          objEnvioPedido.emailPedido = cliente.email;
          objEnvioPedido.telefonoPedido = cliente.telefono;
          objEnvioPedido.comentarioPedido = cliente.comentario;
          objEnvioPedido.documentoReceptor = cliente.documentoReceptor;
          objEnvioPedido.departamentoPedido = cliente.departamento;
          objEnvioPedido.tipoDocumento = cliente.tipoDocumentoReceptor;
          objEnvioPedido.idPrecioEnvio = idPrecioEnvio;
          objEnvioPedido.carritoDD = carritoDD;
          objEnvioPedido.total = computedTotalDesc.value > 0 ? computedTotalDesc.value.toFixed(2).replace(".", "") : computedTotalCheckout.value.toFixed(2).replace(".", "");
          objEnvioPedido.totalSinCambios = totalDesc.value > 0 ? totalDesc.value : totalCheckout.value;
          objEnvioPedido.listaCarritoCompras = products.value;

          objEnvioPedido.nroInterno = objPayExpress.transactionId;
          objEnvioPedido.whatsappEmisor = issuserParam.value.whatsapp;
          objEnvioPedido.telefonoEmisor = issuserParam.value.telefono;
          objEnvioPedido.mailEmisor = issuserParam.value.email;
          objEnvioPedido.nombreComercialEmisor = process.env.VUE_APP_NAME;
          objEnvioPedido.logoEmisor = issuserParam.value.rutaLogo + issuserParam.value.logo;

          objPayExpress.formatPrice = formatPricePayexpress(factura.montoTotal, decimal);
          //seteo el pedido en el objPayExpress
          objPayExpress.pedido = objEnvioPedido;
          objPayExpress.mensaje = mensaje;

          objPayExpress.token = trozo;
          Object.freeze(objPayExpress);

          postPurchase(objPayExpress, factura, objEnvioPedido);

        });
      } catch (err) {
        console.error("Error al cargar payExpress --->" + err)
      }
    };


    const volverCompletado = () => {
      router.push({ name: 'Home' });
    }

    const volverPayExpress = () => {
      errorFE.value = {};
      document.getElementById("buttonModalCloseStatic").style.display = "block";
      modal.hide();
      document.getElementById("iframePago").style.display = "block";
      document.getElementById("iframePago").innerHTML = "";
      errorPayExpress.value = {};
    }

    expose({ cargarPayExpress });

    return {
      issuserParam,
      decimal,
      errorPayExpress,
      styleClass,
      volverCompletado,
      volverPayExpress,
      errorFE
    };
  },
};
</script>

<style>
.text-dinamic {
  color: v-bind(styleClass) !important;
}

.text-card {
  margin-left: 3%;
  font-size: 80%;
}

.text-card-data {
  margin-left: 1%;
  font-size: 80%;
}
</style>